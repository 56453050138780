import React from 'react'

import WhatsApp from "../../assets/images/whatsapp.svg"
import Facebook from "../../assets/images/facebook.svg"

function Share({title, url}) {
    return (
        <div className="social-share">
            <h4>Share</h4>
            <ul>
                <li>
                    <a rel="noreferrer" href={"whatsapp://send?text=" + title + ": " + url} data-action="share/whatsapp/share" target="_blank"> 
                        <img src={WhatsApp} alt="Share on WhatsApp"/>
                    </a>
                </li>
                <li>
                <a rel="noreferrer" href={`https://www.facebook.com/sharer/sharer.php?u=${url}`} target="_blank">
                        <img src={Facebook} alt=""/>
                    </a>
                </li>
            </ul>
        </div>
    )
}

export default Share
