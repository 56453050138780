import React, {useEffect} from "react"

import "./banner.scss"

import BannerImage from "../../../assets/images/banner-def.png"

function PostBanner() {

  const useScript = url => {
    useEffect(() => {
      const script = document.createElement('script');
  
      script.src = url;
      script.async = true;
  
      document.body.appendChild(script);
  
      return () => {
        document.body.removeChild(script);
      }
    }, [url]);
  };

  
  return (
    <div className="banner">
    <ins data-revive-zoneid="3" data-revive-id="b0482e13affac0e362d1916782c9e385"></ins>
    {useScript('//euphona.xyz/adservexv/www/delivery/asyncjs.php')}
    </div>
  )
}

export default PostBanner
