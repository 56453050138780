import React, { Component } from "react"
import { graphql, Link } from "gatsby"
import Layout from "../components/layout"
import Img from "gatsby-image"
import DefImage from "../components/page-components/default-cover"
import Seo from "../components/partials/seo"
import Share from "../components/partials/share"
import PostBanner from "../components/page-components/banner/post-page"



class Post extends Component {
  render() {
    const post = this.props.data.wpPost
    const date = new Date(post.date)
    const siteData = this.props.data.site.siteMetadata
    // console.log(date.getMonth())

    const dateTimeFormat = new Intl.DateTimeFormat("en", {
      year: "numeric",
      month: "long",
      day: "2-digit",
    })
    const [
      { value: month },
      ,
      { value: day },
      ,
      { value: year },
    ] = dateTimeFormat.formatToParts(date)

    return (
      <>
        <Layout className="wrap">

          <Seo title={post.seo.title} description={post.excerpt} image={post.featuredImage && post.featuredImage.node.sourceUrl}/>
          <div className="container">
            
            <div className="post">
              <ul className="post-meta">
                {post.categories.nodes.map(category => (
                  <Link to={"/category/" + category.slug} key={category.slug}>
                    <li>{category.name}</li>
                  </Link>
                ))}
              </ul>
              <h1 dangerouslySetInnerHTML={{ __html: post.title }} />
              <p className="byline">
                {month} {day}, {year}
              </p>

              {post.featuredImage ? (
                <Img
                  fluid={
                    post.featuredImage.node.localFile.childImageSharp.fluid
                  }
                  className="post-image"
                />
              ) : (
                <DefImage />
              )}
                      <PostBanner />

              <div
                className="post-content"
                dangerouslySetInnerHTML={{ __html: post.content }}
              />
            </div>
            <Share title={post.title} url={siteData.siteUrl + post.uri} />

           {
  
             post.tags.nodes.length > 0 ? (
              <div className="tag-list">
              <ul>
                {post.tags.nodes.map(tag => (
                  // <Link to={`/tag/${tag.slug}`} key={tag.slug}>
                    <li key={tag.slug}>{tag.name}</li> 
                  // </Link>
                ))}
              </ul>
            </div>
             ) : ""
           }
          </div>
        </Layout>
      </>
    )
  }
}

export default Post

export const postQuery = graphql`
  query($id: String!) {
    wpPost(id: { eq: $id }) {
      id
      uri
      title
      date
      content
      excerpt
      featuredImage {
        node {
          sourceUrl
          localFile {
            childImageSharp {
              fluid(quality: 80, maxWidth: 800) {
                ...GatsbyImageSharpFluid_noBase64
              }
            }
          }
        }
      }
      seo {
        title
      }
      tags {
        nodes {
          name
          slug
          uri
        }
      }
      categories {
        nodes {
          name
          slug
        }
      }
    }
    site {
      siteMetadata {
        title
        description
        siteUrl
      }
    }
  }
`
